<template>
    <svg
        v-if="name == 'setting'"
        class="fill-stroke transition duration-300 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M20 7h-9" />
        <path d="M14 17H5" />
        <circle cx="17" cy="17" r="3" />
        <circle cx="7" cy="7" r="3" />
    </svg>

    <svg
        v-if="name == 'plus'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line>
    </svg>

    <svg
        v-if="name == 'minus'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
    </svg>

    <svg
        v-if="name == 'down'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <polyline points="6 9 12 15 18 9" />
    </svg>

    <svg
        v-if="name == 'upload'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
        <polyline points="16 6 12 2 8 6"></polyline>
        <line x1="12" y1="2" x2="12" y2="15"></line>
    </svg>

    <svg
        v-if="name == 'delete'"
        class="fill-stroke text-gray-400 cursor-pointer transition duration-300 group-hover:text-red-500 hover:text-red-500"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>

        <!-- <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line> -->
    </svg>

    <svg
        v-if="name == 'back'"
        class="fill-stroke transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
    >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
    </svg>

    <svg
        v-if="name == 'front'"
        class="fill-stroke rotate-180 transition duration-300 group-hover:text-sky-500 group-hover:scale-110"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
    >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
    </svg>

    <svg
        v-if="name == 'trash'"
        class="fill-stroke text-gray-400 cursor-pointer transition duration-300 group-hover:text-red-500 hover:text-red-500"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
        <line x1="10" y1="11" x2="10" y2="17"></line>
        <line x1="14" y1="11" x2="14" y2="17"></line>
    </svg>

    <svg
        v-if="name == 'tl'"
        class="fill-sky-500 cursor-pointer transition duration-300 group-hover:fill-sky-400 hover:fill-sky-400"
        :class="'w-' + size + ' h-' + size"
        viewBox="0 0 100 100"
        stroke-width="0"
    >
        <path
            d="M88.723,12.142C76.419,17.238,23.661,39.091,9.084,45.047c-9.776,3.815-4.053,7.392-4.053,7.392    s8.345,2.861,15.499,5.007c7.153,2.146,10.968-0.238,10.968-0.238l33.62-22.652c11.922-8.107,9.061-1.431,6.199,1.431    c-6.199,6.2-16.452,15.975-25.036,23.844c-3.815,3.338-1.908,6.199-0.238,7.63c6.199,5.246,23.129,15.976,24.082,16.691    c5.037,3.566,14.945,8.699,16.452-2.146c0,0,5.961-37.435,5.961-37.435c1.908-12.637,3.815-24.321,4.053-27.659    C97.307,8.804,88.723,12.142,88.723,12.142z"
        />
    </svg>
</template>

<script>
export default {
    name: "IcoBig",
    props: {
        name: String,
        size: {
            type: Number,
            default: 8,
        },
    },
};
</script>
