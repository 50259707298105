import axios from "axios";
import authHeader from "./header";
const API = "https://pay.igoevent.com/";
// const API = "http://localhost/";
class UserService {
    GET(url, data) {
        return axios.get(API + url, { params: data, headers: authHeader() });
    }
    POST(url, formData) {
        return axios.post(API + url, formData, { headers: authHeader() });
    }
}
export default new UserService();
