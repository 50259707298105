import API from "../../services/api";

export default {
    state: {
        subs: [],
        subsF: [],
        sub: {},
    },

    mutations: {
        setSubs(state, subs) {
            state.subs = subs;
            state.subsF = subs;
        },
        setSubsF(state, subs) {
            state.subsF = subs;
        },
        setSub(state, sub) {
            state.sub = sub;
        },
    },

    actions: {
        getSubscribes(ctx) {
            ctx.dispatch("getProfile");
            API.GET("subscribe/list").then((res) => {
                ctx.commit("setSubs", res.data.subscribes);
            });
        },

        fillSub(ctx) {
            ctx.commit("setSubsF", ctx.state.subs);
        },

        findSub(ctx, s) {
            if (!ctx.state.subs) {
                return null;
            }

            let list = ctx.state.subs;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => {
                    let event = null;
                    let name = null;
                    let secondname = null;
                    let email = null;
                    let phone = null;

                    event = item.event.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    name = item.profile.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    secondname = item.profile.secondname.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    email = item.profile.email.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;
                    phone = item.profile.phone.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1;

                    if (event || name || secondname || email || phone) {
                        return true;
                    }
                    return false;
                });
            }

            if (s.status != "" && s.status != undefined) {
                list = list.filter((item) => s.status.includes(item.status));
            }

            if (list.length != ctx.state.subs.length) {
                s.clear = true;
            }

            ctx.commit("setSubsF", list);
        },

        cancelSub(ctx, sub) {
            let formData = new FormData();
            formData.append("id", sub.id);
            API.POST("subscribe/cancel", formData).then((res) => {
                ctx.commit("setSubs", res.data.subscribes);
            });
        },
    },

    getters: {
        subscribes(state) {
            return state.subsF;
        },
        subscribe(state) {
            return state.sub;
        },
    },
};
