<template>
    <svg
        class="fill-stroke transition duration-300 hover:text-sky-500 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
        <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
</template>

<script>
export default {
    name: "IconHome",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
