import API from "../../services/api";
import router from "@/services/router";

export default {
    state: {
        events: [],
        eventsF: [],
        event: {},
    },

    mutations: {
        setEvents(state, events) {
            state.events = events;
            state.eventsF = events;
        },
        setEventsF(state, events) {
            state.eventsF = events;
        },
        setEvent(state, event) {
            localStorage.setItem("event_id", event.id);
            state.event = event;
        },
    },

    actions: {
        getEvents(ctx) {
            ctx.dispatch("getProfile");
            API.GET("event/list").then((res) => {
                ctx.commit("setEvents", res.data.events);
            });
        },

        getLanding(ctx, id) {
            API.GET("event/one", { id: id }).then((res) => {
                ctx.commit("setEvent", res.data.event);
            });
        },

        getSuccess(ctx) {
            // TODO вывести чек???? или что???
            let id = localStorage.getItem("event_id");
            if (id && id > 0) {
                API.GET("event/one", { id: id }).then((res) => {
                    ctx.commit("setEvent", res.data.event);
                    // ctx.commit("setPayment", res.data.payment);
                });
            }
        },

        fillEvent(ctx) {
            ctx.commit("setEventsF", ctx.state.events);
        },

        findEvent(ctx, s) {
            if (!ctx.state.events) {
                return null;
            }

            let list = ctx.state.events;

            if (s.findme != "" && s.findme != undefined) {
                list = list.filter((item) => item.name.toLowerCase().indexOf(s.findme.toLowerCase()) !== -1);
            }

            if (list.length != ctx.state.events.length) {
                s.clear = true;
            }

            ctx.commit("setEventsF", list);
        },

        editEvent(ctx, event) {
            ctx.commit("setEvent", event);
            ctx.dispatch("setPopup", "editEvent");
        },

        saveEvent(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id ?? null);
            formData.append("account_id", data.account_id ?? ctx.rootState.profile.account);
            formData.append("biblioevent_id", data.biblioevent_id ?? null);
            formData.append("event_id", data.event_id ?? null);
            formData.append("name", data.name);
            formData.append("url", data.url ?? null);
            formData.append("description", data.description ?? null);
            formData.append("stop_at", data.stop_at ?? null);
            formData.append("type1", parseInt(data.type1));
            formData.append("price1", parseInt(data.price1));
            formData.append("type2", parseInt(data.type2));
            formData.append("price2", parseInt(data.price2));
            formData.append("type3", parseInt(data.type3));
            formData.append("price3", parseInt(data.price3));
            formData.append("type4", parseInt(data.type4));
            formData.append("price4", parseInt(data.price4));
            formData.append("type5", parseInt(data.type5));
            formData.append("price5", parseInt(data.price5));
            if (data.status === true || data.status == 1) {
                formData.append("status", 1);
            } else {
                formData.append("status", 0);
            }
            formData.append("del", data.del ?? null);

            API.POST("event/create", formData).then((res) => {
                console.log(res.data);
                ctx.commit("setEvents", res.data.events);
            });
        },

        delEvent(ctx, event) {
            let formData = new FormData();
            formData.append("id", event.id);
            API.POST("event/delete", formData).then((res) => {
                // let events = ctx.state.events.filter((item) => item.id !== event.id);
                ctx.commit("setEvents", res.data.events);
            });
        },

        recoverEvent(ctx, event) {
            let formData = new FormData();
            formData.append("id", event.id);
            API.POST("event/recover", formData).then((res) => {
                ctx.commit("setEvents", res.data.events);
            });
        },

        buyEvent(ctx, form) {
            let formData = new FormData();
            formData.append("event_id", form.event_id);
            formData.append("type", form.type);
            formData.append("name", form.name);
            formData.append("secondname", form.secondname);
            formData.append("email", form.email);
            formData.append("phone", form.phone);
            API.POST("pay/pay", formData).then((res) => {
                console.log(res.data);
                ctx.commit("setAlert", res.data.alert);
                if (res.data.code == 210) {
                    router.push("/success");
                }
                if (res.data.code == 200) {
                    window.location.replace(res.data.redirect);
                }
            });
        },
    },

    getters: {
        events(state) {
            return state.eventsF;
        },
        event(state) {
            return state.event;
        },
    },
};
