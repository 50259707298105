import axios from "axios";

const authService = {
    currentUser: null,
    isLogged() {
        return !!localStorage.getItem("AUTH");
    },
    getToken() {
        return localStorage.getItem("AUTH");
    },
    async getUser() {
        try {
            if (!this.currentUser) {
                const { status, data } = await axios.get("/user/data");
                if (status === 200) {
                    this.currentUser = data;
                }
            }
        } catch (e) {
            return null;
        }

        return this.currentUser;
    },
};

export default authService;
