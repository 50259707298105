<template>
    <div class="p-8">
        <div class="flex justify-between mb-8">
            <h2 class="text-3xl text-sky-700/50 font-light leading-3">Аккаунты</h2>
            <div class="flex justify-center items-center p-2 bg-white rounded cursor-pointer" @click="addPortfolio('Новый портфель')">
                <div class="mr-2 text-sky-500"><IcoBig name="plus" :size="5" /></div>
                <span class="text-sm text-slate-500">ДОБАВИТЬ</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AccountPage",

    data() {
        return {
            moment: moment,
            miniPopup: null,
        };
    },

    computed: {
        ...mapGetters(["account"]),
    },

    methods: {
        ...mapActions([]),
    },

    mounted() {},
};
</script>
