import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/pages/Main";
import LoginPage from "@/pages/Login";
import ProfilePage from "@/pages/Profile";
import AccountPage from "@/pages/Account";
import EventsPage from "@/pages/Events";
import SubscribesPage from "@/pages/Subscribes";
import PaymentsPage from "@/pages/Payments";
import LandingPage from "@/pages/Landing";
import SuccessPage from "@/pages/Success";

const routes = [
    {
        path: "/",
        name: "home",
        meta: { title: "Подписки на igoevent.com" },
        component: MainPage,
    },
    {
        path: "/login",
        name: "login",
        meta: { title: "Подписки на igoevent.com" },
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfilePage,
    },
    {
        path: "/account",
        name: "account",
        component: AccountPage,
    },
    {
        path: "/events",
        name: "events",
        meta: { title: "Создать подписку" },
        component: EventsPage,
    },
    {
        path: "/subscribes",
        name: "subscribes",
        meta: { title: "Подписки" },
        component: SubscribesPage,
    },
    {
        path: "/payments",
        name: "payments",
        meta: { title: "Платежи" },
        component: PaymentsPage,
    },
    {
        path: "/l/:id",
        name: "landing",
        meta: { title: "Купить подписку" },
        component: LandingPage,
    },
    {
        path: "/success",
        name: "success",
        meta: { title: "Спасибо за покупку" },
        component: SuccessPage,
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "Подписки на igoevent.com";

    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "login" &&
        to.name !== "home" &&
        to.name !== "landing"
    ) {
        // redirect the user to the login page
        return { name: "login" };
    }
});

export default router;
