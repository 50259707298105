<template>
    <div class="w-full">
        <div class="flex">
            <div class="w-1/2 mx-auto bg-white p-8 text-center">
                <template v-if="alert">
                    <div
                        class="text-2xl"
                        :class="{
                            'text-green-500': alert.type == 'success',
                            'text-sky-500': alert.type == 'info',
                            'text-red-500': alert.type == 'error',
                        }"
                    >
                        {{ alert.msg }}
                    </div>
                </template>

                <h2 class="text-lg text-green-500">Спасибо за покупку</h2>
                <h1 class="text-3xl mb-2">{{ event.name }}</h1>
                <!-- <p class="text-lg mb-8">{{ event.description }}</p> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "SuccessPage",

    data() {
        return {
            form: {},
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["alert", "event"]),
    },

    methods: {
        ...mapActions(["getSuccess"]),
    },

    mounted() {
        this.getSuccess();
    },
};
</script>
