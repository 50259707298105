import { createApp } from "vue";
import App from "@/App.vue";
import "@/assets/tailwind.css";
import components from "@/components";

import store from "@/store/index";
import router from "@/services/router";

const app = createApp(App);

components.forEach((component) => {
    app.component(component.name, component);
});

app.use(store);
app.use(router);

app.mount("#app");
