<template>
    <div class="w-2/3 min-h-min h-2/3 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-black text-white">СОЗДАТЬ ПОДПИСКУ</h3>

        <div class="flex flex-col p-10">
            <div class="grid gap-4">
                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Название</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="subscribe.name" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Стоимость</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="subscribe.price" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Тип</label>

                    <div class="grid grid-cols-4 gap-4 w-2/3">
                        <div
                            class="h-8 flex justify-center items-center rounded bg-slate-400 hover:bg-sky-300 text-white cursor-pointer"
                            :class="{ '!bg-sky-400 ': subscribe.type == 1 }"
                            @click="subscribe.type = 1"
                        >
                            Дневная
                        </div>
                        <div
                            class="h-8 flex justify-center items-center rounded bg-slate-400 hover:bg-sky-300 text-white cursor-pointer"
                            :class="{ '!bg-sky-400 ': subscribe.type == 2 }"
                            @click="subscribe.type = 2"
                        >
                            Недельная
                        </div>
                        <div
                            class="h-8 flex justify-center items-center rounded bg-slate-400 hover:bg-sky-300 text-white cursor-pointer"
                            :class="{ '!bg-sky-400 ': subscribe.type == 3 }"
                            @click="subscribe.type = 3"
                        >
                            Месячная
                        </div>
                        <div
                            class="h-8 flex justify-center items-center rounded bg-slate-400 hover:bg-sky-300 text-white cursor-pointer"
                            :class="{ '!bg-sky-400 ': subscribe.type == 4 }"
                            @click="subscribe.type = 4"
                        >
                            Годовая
                        </div>
                    </div>
                </div>

                <div class="flex justify-center mt-20">
                    <div class="btn btn-green py-4 px-10" @click="saveSub(subscribe), closePopup()">Сохранить</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ViewPayment",

    data() {
        return {
            subscribe: {},
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s"]),
    },

    methods: {
        ...mapActions(["saveSub", "closePopup"]),
    },
};
</script>
