<template>
    <svg
        class="fill-stroke transition duration-300 hover:text-sky-500 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="9" y1="12" x2="15" y2="12"></line>
        <line x1="12" y1="9" x2="12" y2="15"></line>
    </svg>
</template>

<script>
export default {
    name: "IconPlus",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
