<template>
    <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-black text-white">СОЗДАТЬ СОБЫТИЕ</h3>

        <div class="flex flex-col p-10">
            <div class="grid gap-4">
                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Название</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="event.name" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Описание</label>
                    <textarea class="w-2/3 h-32 py-1 px-3 bg-slate-100 text-slate-500 rounded text-sm" v-model="event.description"> </textarea>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Тип</label>

                    <div class="w-2/3 grid grid-cols-5 gap-4 text-sm">
                        <div v-if="event.type1 == 1" class="btn btn-green" @click="event.type1 = 0">Дневная</div>
                        <div v-else class="btn btn-grey" @click="event.type1 = 1">Дневная</div>

                        <div v-if="event.type2 == 1" class="btn btn-green" @click="event.type2 = 0">Недельная</div>
                        <div v-else class="btn btn-grey" @click="event.type2 = 1">Недельная</div>

                        <div v-if="event.type3 == 1" class="btn btn-green" @click="event.type3 = 0">Месячная</div>
                        <div v-else class="btn btn-grey" @click="event.type3 = 1">Месячная</div>

                        <div v-if="event.type4 == 1" class="btn btn-green" @click="event.type4 = 0">Квартальная</div>
                        <div v-else class="btn btn-grey" @click="event.type4 = 1">Квартальная</div>

                        <div v-if="event.type5 == 1" class="btn btn-green" @click="event.type5 = 0">Годовая</div>
                        <div v-else class="btn btn-grey" @click="event.type5 = 1">Годовая</div>

                        <input
                            type="text"
                            class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-right"
                            placeholder="Стоимость"
                            :disabled="!event.type1"
                            v-model="event.price1"
                        />
                        <input
                            type="text"
                            class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-right"
                            placeholder="Стоимость"
                            :disabled="!event.type2"
                            v-model="event.price2"
                        />
                        <input
                            type="text"
                            class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-right"
                            placeholder="Стоимость"
                            :disabled="!event.type3"
                            v-model="event.price3"
                        />
                        <input
                            type="text"
                            class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-right"
                            placeholder="Стоимость"
                            :disabled="!event.type4"
                            v-model="event.price4"
                        />
                        <input
                            type="text"
                            class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-right"
                            placeholder="Стоимость"
                            :disabled="!event.type5"
                            v-model="event.price5"
                        />
                    </div>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Ссылка</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-sm" v-model="event.url" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Остановить с</label>
                    <input type="date" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded text-sm" v-model="event.stop_at" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Статус</label>
                    <div class="btn btn-grey" :class="{ '!bg-green-400 ': event.status == 1 }" @click="event.status = !event.status">
                        <span v-if="event.status == 1">Активна</span>
                        <span v-else>Выключена</span>
                    </div>
                </div>

                <div class="flex justify-end mt-8">
                    <div class="btn btn-green py-2 px-6" @click="saveEvent(event), closePopup()">Сохранить</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddEvent",

    data() {
        return {
            event: { status: 1 },
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s"]),
    },

    methods: {
        ...mapActions(["saveEvent", "closePopup"]),
    },
};
</script>
