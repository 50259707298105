<template>
    <div class="w-full">
        <div class="lg:flex">
            <div class="w-full lg:w-1/2 bg-white p-8">
                <h2 class="text-sm text-slate-500">Купить подписку</h2>
                <h1 class="text-2xl lg:text-3xl mb-4 font-bold">{{ event.name }}</h1>
                <p class="text-sm lg:text-lg mb-8">{{ event.description }}</p>
                <hr />
                <!-- <p class="">Для  перейдите на сайт</p> -->
                <div class="flex flex-col">
                    <a class="text-sm mt-4 text-slate-400" href="https://id.igoevent.com">Управление подписками</a>
                    <a class="text-sm mt-2 text-slate-400" href="https://kultr.ru/assets/files/public-offer-kultr.ru.pdf"
                        >Ссылка на публичную офферту</a
                    >
                </div>
            </div>

            <div class="w-full lg:w-1/2 bg-white p-8">
                <template v-if="alert">
                    <div
                        class="w-full text-center text-2xl"
                        :class="{
                            'text-green-500': alert.type == 'success',
                            'text-sky-500': alert.type == 'info',
                            'text-red-500': alert.type == 'error',
                        }"
                    >
                        {{ alert.msg }}
                    </div>
                </template>

                <template v-if="showForm">
                    <template v-if="event.type1">
                        <div
                            class="flex justify-between items-center mb-4 p-4 text-lg rounded bg-slate-50 hover:bg-yellow-400 cursor-pointer"
                            :class="{ '!bg-green-400 ': form.type == 1 }"
                            @click="(form.type = 1), (form.price = event.price1)"
                        >
                            <div>
                                <h3>Однодневная подписка</h3>
                                <p class="text-xs text-slate-500 mt-1">Сумма {{ event.price1 }} ₽ будет списываться ежедневно</p>
                            </div>
                            <div class="text-lg font-bold">{{ event.price1 }} ₽</div>
                        </div>
                    </template>

                    <template v-if="event.type2">
                        <div
                            class="flex justify-between items-center mb-4 p-4 text-lg rounded bg-slate-50 hover:bg-yellow-400 cursor-pointer"
                            :class="{ '!bg-green-400 ': form.type == 2 }"
                            @click="(form.type = 2), (form.price = event.price2)"
                        >
                            <div>
                                <h3>Недельная подписка</h3>
                                <p class="text-xs text-slate-500 mt-1">Сумма {{ event.price2 }} ₽ будет списываться еженедельно</p>
                            </div>
                            <div class="text-lg font-bold">{{ event.price2 }} ₽</div>
                        </div>
                    </template>

                    <template v-if="event.type3">
                        <div
                            class="flex justify-between items-center mb-4 p-4 text-lg rounded bg-slate-50 hover:bg-yellow-400 cursor-pointer"
                            :class="{ '!bg-green-400 ': form.type == 3 }"
                            @click="(form.type = 3), (form.price = event.price3)"
                        >
                            <div>
                                <h3>Месячная подписка</h3>
                                <p class="text-xs text-slate-500 mt-1">Сумма {{ event.price3 }} ₽ будет списываться ежемесячно</p>
                            </div>
                            <div class="text-lg font-bold">{{ event.price3 }} ₽</div>
                        </div>
                    </template>

                    <template v-if="event.type4">
                        <div
                            class="flex justify-between items-center mb-4 p-4 text-lg rounded bg-slate-50 hover:bg-yellow-400 cursor-pointer"
                            :class="{ '!bg-green-400 ': form.type == 4 }"
                            @click="(form.type = 4), (form.price = event.price4)"
                        >
                            <div>
                                <h3>Квартальная подписка</h3>
                                <p class="text-xs text-slate-500 mt-1">Сумма {{ event.price4 }} ₽ будет списываться ежеквартально</p>
                            </div>
                            <div class="text-lg font-bold">{{ event.price4 }} ₽</div>
                        </div>
                    </template>

                    <template v-if="event.type5">
                        <div
                            class="flex justify-between items-center mb-4 p-4 text-lg rounded bg-slate-50 hover:bg-yellow-400 cursor-pointer"
                            :class="{ '!bg-green-400 ': form.type == 5 }"
                            @click="(form.type = 5), (form.price = event.price5)"
                        >
                            <div>
                                <h3>Годовая подписка</h3>
                                <p class="text-xs text-slate-500 mt-1">Сумма {{ event.price5 }} ₽ будет списываться ежегодно</p>
                            </div>
                            <div class="text-lg font-bold">{{ event.price5 }} ₽</div>
                        </div>
                    </template>

                    <div class="flex flex-col lg:flex-row gap-4 mb-4">
                        <div class="flex flex-col w-full">
                            <label class="text-xs text-slate-500">Фамилия</label>
                            <input type="text" class="h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="form.secondname" />
                        </div>

                        <div class="flex flex-col w-full">
                            <label class="text-xs text-slate-500">Имя</label>
                            <input type="text" class="h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="form.name" />
                        </div>
                    </div>
                    <div class="flex flex-col lg:flex-row gap-4 mb-4">
                        <div class="flex flex-col w-full">
                            <label class="text-xs text-slate-500">Email</label>
                            <input type="text" class="h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="form.email" />
                        </div>

                        <div class="flex flex-col w-full">
                            <label class="text-xs text-slate-500">Телефон</label>
                            <input type="text" class="h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="form.phone" />
                        </div>
                    </div>
                    <div class="flex justify-end mt-8">
                        <div
                            v-if="!form.type || !form.secondname || !form.name || !form.email || !form.phone"
                            class="btn btn-grey py-2 px-6 !cursor-not-allowed"
                        >
                            Оплатить
                        </div>
                        <div v-else class="btn btn-green py-2 px-6" @click="(form.event_id = event.id), buyEvent(form), (showForm = false)">
                            Оплатить
                        </div>
                    </div>
                </template>
                <template v-else>
                    <h2 class="flex justify-center items-center text-lg text-slate-500">Платеж обрабатывается ...</h2>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "PaymentsPage",

    data() {
        return {
            showForm: true,
            // form: { secondname: "Потапов", name: "Александр", email: "studiobaraban@gmail.com", phone: "89118424111", type: 1 },
            form: {},
            id: this.$route.params.id,
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["alert", "event"]),
    },

    methods: {
        ...mapActions(["getLanding", "buyEvent"]),
    },

    mounted() {
        this.getLanding(this.id);
    },
};
</script>
