<template>
    <div class="w-full">
        <div class="bg-white p-4">
            <div class="flex gap-4 justify-between items-center">
                <h2 class="text-sm text-slate-500">
                    СОБЫТИЯ <sup>{{ events_length }}</sup>
                </h2>

                <div class="hidden lg:flex">
                    <input
                        type="text"
                        class="h-8 w-1/2 px-3 py-2 text-sm bg-slate-100"
                        placeholder="Название"
                        v-model="s.findme"
                        @input="findEvent(s)"
                    />

                    <div class="w-20">
                        <div
                            v-if="s.clear"
                            @click="clear()"
                            class="w-14 text-white bg-gray-400 hover:bg-gray-300 text-sm text-center p-1 rounded cursor-pointer"
                        >
                            Сброс
                        </div>
                    </div>
                </div>
                <div class="btn btn-green" @click="setPopup('addEvent')"><IconPlus /> <span class="ml-2">Создать</span></div>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid lg:grid-cols-11 mb-4 px-2 text-xs text-slate-400">
                <p class="col-span-3">Название</p>
                <p class="text-center">Создана</p>
                <p class="text-center">Остановить с</p>
                <p class="text-center col-span-3">Типы подписки</p>
                <p class="text-center">Витрина</p>
                <p class="text-center">Купить</p>
                <p class="text-right">Статус</p>
            </div>
            <template v-for="item in events" :key="item.id">
                <template v-if="item.del == 0">
                    <div class="grid grid-cols-3 lg:grid-cols-11 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                        <div class="flex items-center col-span-3">
                            #{{ item.id }}
                            <p class="text-lg ml-4">{{ item.name }}</p>
                        </div>

                        <p class="col-span-3 lg:col-span-1 text-center">{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>

                        <div class="col-span-3 lg:col-span-1 text-center">
                            <p v-if="item.stop_at" class="text-red-500">{{ moment(item.stop_at).format("DD.MM.YYYY") }}</p>
                        </div>

                        <div class="flex justify-around text-center col-span-3 my-4 lg:my-0">
                            <div v-if="item.type1 == 1">
                                <p class="text-xs text-slate-500">Дневная</p>
                                <p v-if="parseInt(item.price1)" class="text-lg font-bold">{{ parseInt(item.price1).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type2 == 1">
                                <p class="text-xs text-slate-500">Недельная</p>
                                <p v-if="parseInt(item.price2)" class="text-lg font-bold">{{ parseInt(item.price2).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type3 == 1">
                                <p class="text-xs text-slate-500">Месячная</p>
                                <p v-if="parseInt(item.price3)" class="text-lg font-bold">{{ parseInt(item.price3).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type4 == 1">
                                <p class="text-xs text-slate-500">Квартальная</p>
                                <p v-if="parseInt(item.price4)" class="text-lg font-bold">{{ parseInt(item.price4).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type5 == 1">
                                <p class="text-xs text-slate-500">Годовая</p>
                                <p v-if="parseInt(item.price5)" class="text-lg font-bold">{{ parseInt(item.price5).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                        </div>

                        <p class="flex justify-center" @click="gotoUrl(item.url)"><IconHome /></p>

                        <p class="flex justify-center" @click="gotoLanding(item.id)"><IconLink /></p>

                        <div class="flex justify-end items-center">
                            <div class="circle mr-4" :class="{ '!bg-green-400 ': item.status == 1 }"></div>
                            <p class="flex justify-end" @click="editEvent(item)"><IconEdit /></p>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div class="px-4 mt-20">
            <div class="flex justify-between items-center">
                <h2 class="text-sm text-slate-500">АРХИВ</h2>
            </div>
        </div>

        <div class="bg-white p-4 mt-4">
            <div class="hidden lg:grid lg:grid-cols-10 mb-4 px-2 text-xs text-slate-400">
                <p class="col-span-3">Название</p>
                <p class="text-center">Создана</p>
                <p class="text-center">Остановить с</p>
                <p class="text-center col-span-3">Типы подписки</p>
                <p class="text-right">Ссылка</p>
                <p class="text-right">Восстановить</p>
            </div>
            <template v-for="item in events" :key="item.id">
                <template v-if="item.del == 1">
                    <div class="grid grid-cols-3 lg:grid-cols-10 items-center mb-px p-2 text-sm tabular-nums hover:bg-slate-100 odd:bg-slate-50">
                        <div class="flex items-center col-span-3">
                            #{{ item.id }}
                            <p class="text-lg ml-4">{{ item.name }}</p>
                        </div>

                        <p class="col-span-3 lg:col-span-1 text-center">{{ moment(item.create_at).format("DD.MM.YYYY") }}</p>

                        <div class="col-span-3 lg:col-span-1 text-center">
                            <p v-if="item.stop_at" class="text-red-500">{{ moment(item.stop_at).format("DD.MM.YYYY") }}</p>
                        </div>

                        <div class="flex justify-around text-center col-span-3 my-4 lg:my-0">
                            <div v-if="item.type1 == 1">
                                <p class="text-xs text-slate-500">Дневная</p>
                                <p v-if="parseInt(item.price1)" class="text-lg font-bold">{{ parseInt(item.price1).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type2 == 1">
                                <p class="text-xs text-slate-500">Недельная</p>
                                <p v-if="parseInt(item.price2)" class="text-lg font-bold">{{ parseInt(item.price2).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type3 == 1">
                                <p class="text-xs text-slate-500">Месячная</p>
                                <p v-if="parseInt(item.price3)" class="text-lg font-bold">{{ parseInt(item.price3).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type4 == 1">
                                <p class="text-xs text-slate-500">Квартальная</p>
                                <p v-if="parseInt(item.price4)" class="text-lg font-bold">{{ parseInt(item.price4).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                            <div v-if="item.type5 == 1">
                                <p class="text-xs text-slate-500">Годовая</p>
                                <p v-if="parseInt(item.price5)" class="text-lg font-bold">{{ parseInt(item.price5).toLocaleString() }} ₽</p>
                                <p v-else>-</p>
                            </div>
                        </div>

                        <p class="flex justify-end" @click="gotoLanding(item.id)"><IconLink /></p>

                        <div class="flex justify-end items-center">
                            <p class="flex justify-end" @click="recoverEvent(item)"><IconRe /></p>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>

    <template v-if="s.popup == 'addEvent'"><AddEvent /></template>
    <template v-if="s.popup == 'editEvent'"><EditEvent /></template>

    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import AddEvent from "../components/AddEvent.vue";
import EditEvent from "../components/EditEvent.vue";

export default {
    name: "EventsPage",

    components: { AddEvent, EditEvent },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "events"]),

        events_length() {
            let i = 0;
            this.events?.forEach((item) => {
                if (item.del == 0) {
                    i++;
                }
            });
            return i;
        },
    },

    methods: {
        ...mapActions(["setPopup", "closePopup", "setSettings", "clearSettings", "getEvents", "editEvent", "findEvent", "fillEvent", "recoverEvent"]),

        clear() {
            this.clearSettings();
            this.fillEvent();
        },

        gotoLanding(id) {
            let route = this.$router.resolve({ path: "/l/" + id });
            window.open(route.href);
        },

        gotoUrl(url) {
            window.open(url);
        },
    },

    mounted() {
        this.getEvents();
    },
};
</script>
<style>
.wrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
}
</style>
