import API from "../../services/api";
import router from "@/services/router";

export default {
    state: {
        account: [],
        portfolios: [],
        portfoliosF: [],
        portfolio: {},
        instruments: [],
        instrumentsF: [],
        instrument: {},
    },

    mutations: {
        setAccount(state, account) {
            state.account = account;
        },

        setPortfolios(state, portfolios) {
            state.portfolios = portfolios;
            state.portfoliosF = portfolios;
        },

        setPortfoliosF(state, portfolios) {
            state.portfoliosF = portfolios;
        },

        setPortfolio(state, portfolio) {
            state.portfolio = portfolio;
            localStorage.setItem("portfolio", JSON.stringify(portfolio));
        },

        setInstruments(state, instruments) {
            state.instruments = instruments;
            state.instrumentsF = instruments;
            localStorage.setItem("instruments", JSON.stringify(instruments));
        },

        setInstrumentsF(state, instruments) {
            state.instrumentsF = instruments;
        },

        setInstrument(state, instrument) {
            state.instrument = instrument;
            localStorage.setItem("instrument", JSON.stringify(instrument));
        },
    },

    actions: {
        getAccount(ctx) {
            API.GET("account/account/portfolios").then((res) => {
                ctx.commit("setAccount", res.data.account);
                ctx.commit("setPortfolios", res.data.portfolios);
                ctx.commit("setInstruments", res.data.instruments);
            });
        },

        addPortfolio(ctx, name) {
            console.log("addPortfolio", name);
            let formData = new FormData();
            formData.append("name", name);
            API.POST("account/account/add-portfolio", formData).then((res) => {
                ctx.commit("setPortfolios", res.data.portfolios);
            });
        },

        pickPortfolio(ctx, portfolio) {
            // console.log("ctx.state.instruments", ctx.state.instruments);
            ctx.commit("setPortfolio", portfolio);
            let instrumentsF = ctx.state.instruments.filter((i) => i.portfolio_id == portfolio.id);
            // console.log("setInstrumentsF", instrumentsF);
            ctx.commit("setInstrumentsF", instrumentsF);
            router.push("/portfolio");
        },

        loadPortfolio(ctx) {
            console.log("loadPortfolio");
            // если нет во vuex - получить
            if (!ctx.state.portfolio?.id) {
                // если есть в localStorage - загрузить все
                let portfolio = JSON.parse(localStorage.getItem("portfolio"));
                let instruments = JSON.parse(localStorage.getItem("instruments"));

                if (portfolio && instruments) {
                    ctx.commit("setInstruments", instruments);
                    ctx.dispatch("pickPortfolio", portfolio);
                    ctx.commit("setBots", null);
                    API.GET("bot/bots", { portfolio_id: portfolio.id }).then((res) => {
                        ctx.commit("setBots", res.data.bots);
                    });
                } else {
                    router.push("/account");
                }
            }
        },

        pickInstrument(ctx, instrument) {
            // console.log("pickInstrument", instrument);
            // ctx.dispatch("setBot", instrument);
            ctx.commit("setBot", instrument);
            localStorage.setItem("instrument", JSON.stringify(instrument));

            // ручная торговля
            if (instrument.type_id == 1) {
                router.push("/portfolio/instrument");
            }
            // ???
            if (instrument.type_id == 2) {
                router.push("/bot/stat");
            }
            // буй бот
            if (instrument.type_id == 3) {
                router.push("/bot/stat");
            }
        },

        loadInstrument(ctx) {
            console.log("loadInstrument");
            // если нет во vuex - получить
            if (!ctx.state.instrument?.id) {
                // если есть в localStorage - загрузить все
                let portfolio = JSON.parse(localStorage.getItem("portfolio"));
                let instruments = JSON.parse(localStorage.getItem("instruments"));

                if (portfolio && instruments) {
                    ctx.commit("setInstruments", instruments);
                    ctx.dispatch("pickPortfolio", portfolio);
                    ctx.commit("setBots", null);
                    API.GET("bot/bots", { portfolio_id: portfolio.id }).then((res) => {
                        ctx.commit("setBots", res.data.bots);
                    });
                } else {
                    router.push("/account");
                }
            }
        },
    },

    getters: {
        account(state) {
            return state.account;
        },
        portfolios(state) {
            return state.portfoliosF;
        },
        portfolio(state) {
            return state.portfolio;
        },
        instruments(state) {
            return state.instrumentsF;
        },
        instrument(state) {
            return state.instrument;
        },
    },
};
