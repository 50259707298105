<template>
    <div class="menu fixed z-50 bg-black w-full h-20">
        <i-menu></i-menu>
    </div>
    <div class="min-h-screen bg-slate-100 p-4 pt-24">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import iMenu from "./components/Menu.vue";

export default {
    name: "App",
    components: {
        iMenu,
    },
    methods: {
        ...mapActions(["getProfile"]),
    },
    mounted() {
        // this.getProfile();
    },
};
</script>

<style>
.menu {
    background: #000 url("/src/img/bg.jpg") bottom left;
}
</style>
