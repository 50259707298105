import IcoBig from "@/components/IcoBig";
import Menu from "@/components/Menu";

import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconClose from "@/components/icons/IconClose";
import IconLink from "@/components/icons/IconLink";
import IconRe from "@/components/icons/IconRe";
import IconHome from "@/components/icons/IconHome";

export default [IcoBig, Menu, IconPlus, IconEdit, IconDelete, IconClose, IconLink, IconRe, IconHome];
