import API from "../../services/api";
import axios from "axios";
import router from "@/services/router";

export default {
    state: {
        menu: [],
        profile: [],
    },

    mutations: {
        setMenu: (state, menu) => {
            state.menu = menu;
        },
        setProfile: (state, profile) => {
            localStorage.setItem("profile", JSON.stringify(profile));
            state.profile = profile;
        },
        setAccount: (state, account) => {
            localStorage.setItem("account", account);
            state.account = account;
        },
        setCustomers: (state, customers) => {
            state.customers = customers;
        },
    },

    actions: {
        login(ctx, data) {
            console.log("Login");
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("password", data.password);
            axios.post("https://pay.igoevent.com/site/login", formData).then((res) => {
                console.log(res.data);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.profile);
                    ctx.commit("setAccount", res.data.account);
                    router.push("/subscribes");
                }
            });
        },

        registration(ctx, data) {
            let formData = new FormData();
            formData.append("username", data.username);
            formData.append("email", data.email);
            formData.append("password", data.password);
            axios.post("https://pay.igoevent.com/site/registration", formData).then((res) => {
                console.log(res.data);
                if (res.data && res.data.token) {
                    localStorage.setItem("AUTH", res.data.token);
                    ctx.commit("setProfile", res.data.profile);
                    ctx.commit("setAccount", res.data.account);
                    router.push("/profile");
                }
            });
        },

        logout(ctx) {
            localStorage.removeItem("AUTH");
            localStorage.removeItem("profile");
            ctx.commit("setProfile", null);
            ctx.commit("menu", null);
            ctx.commit("setAccount", null);
            router.push({ name: "login" });
        },

        getProfile(ctx) {
            console.log("value", router.currentRoute);
            let profile = localStorage.getItem("profile");
            let account = localStorage.getItem("account");
            if (profile && account) {
                ctx.commit("setProfile", JSON.parse(profile));
                ctx.commit("setAccount", account);
            } else {
                ctx.commit("setProfile", null);
                // if (router.currentRoute.value.path !== "/landing") {
                // router.push("/login");
                // }
            }
        },

        getCustomers(ctx) {
            API.GET("user/list").then((res) => {
                console.log("customers", res.data);
                ctx.commit("setCustomers", res.data.customers);
            });
        },
    },

    getters: {
        menu(state) {
            return state.menu;
        },
        profile(state) {
            return state.profile;
        },
        customers(state) {
            return state.customers;
        },
    },
};
