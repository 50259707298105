<template>
    <svg
        class="fill-stroke transition duration-300 hover:text-sky-500 cursor-pointer"
        :class="'w-' + size + ' h-' + size"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
    </svg>
</template>

<script>
export default {
    name: "IconLink",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
